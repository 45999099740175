import { mdiPlus, mdiSend, mdiTrashCan, mdiUpload } from "@mdi/js";
import Icon from "@mdi/react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { type Dayjs } from "dayjs";
import { enqueueSnackbar } from "notistack";
import { ChangeEvent, useCallback, useMemo, useRef, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import { z } from "zod";
import { massUpdateByApiDeliveryTerms } from "../../services/deliveryTerms";

// get zod object keys recursively
const zodKeys = <T extends z.ZodTypeAny>(schema: T): string[] => {
  // make sure schema is not null or undefined
  if (schema === null || schema === undefined) return [];
  // check if schema is nullable or optional
  if (schema instanceof z.ZodNullable || schema instanceof z.ZodOptional)
    return zodKeys(schema.unwrap());
  // check if schema is an array
  if (schema instanceof z.ZodArray) return zodKeys(schema.element);
  // check if schema is an object
  if (schema instanceof z.ZodObject) {
    // get key/value pairs from schema
    const entries = Object.entries(schema.shape);
    // loop through key/value pairs
    return entries.flatMap(([key, value]) => {
      // get nested keys
      const nested =
        value instanceof z.ZodType
          ? zodKeys(value).map((subKey) => `${key}.${subKey}`)
          : [];
      // return nested keys
      return nested.length ? nested : key;
    });
  }
  // return empty array
  return [];
};

const mapWorkbook = (workbook: XLSX.WorkBook, sheetName?: string) => {
  const worksheet = workbook.Sheets[sheetName || workbook.SheetNames[0]];
  const sheetData = XLSX.utils.sheet_to_json(worksheet, {
    header: 1,
    blankrows: false,
    raw: false,
  });

  return sheetData as string[][];
};

const selectHeader = (data: string[][], selectedRows: [0]) => {
  const [selectedRowIndex] = selectedRows;
  // We consider data above header to be redundant
  const trimmedData = data.slice(selectedRowIndex + 1);
  return { headerValues: data[selectedRowIndex], data: trimmedData };
};

const parseSheetData = (headerValues: string[], sheetData: string[][]) => {
  // Transformar os dados da planilha em um formato adequado
  return sheetData.map((row: any[]) => {
    return row.reduce((acc, cell, index) => {
      acc[headerValues[index]] = cell;
      return acc;
    }, {});
  });
};

const itemSchema = z.object({
  purchase_order: z.string().length(10),
  purchase_order_item: z.string().min(1),
  schedule_line: z.number().int().positive(),
  // delivery_date: z.date().optional(),
  // statistical_delivery_date: z.date().optional(),
  delivery_date: z
    .custom<Dayjs>((val) => val instanceof dayjs, "Invalid date")
    .optional(),
  statistical_delivery_date: z
    .custom<Dayjs>((val) => val instanceof dayjs, "Invalid date")
    .optional(),
  justification: z.string().optional(),
});

export const formSchema = z.object({
  items: z.array(itemSchema),
});

type ItemSchema = z.infer<typeof itemSchema>;
export type FormSchema = z.infer<typeof formSchema>;

const DeliveryTerms = () => {
  const [excelLoading, setExcelLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [listenerAdded, setListenerAdded] = useState(false);
  const formDataRef = useRef<any>(null);
  const navigate = useNavigate();
  const { control, handleSubmit, setValue } = useForm<FormSchema>({
    defaultValues: {
      items: [
        {
          purchase_order: "",
          purchase_order_item: "",
          schedule_line: 1,
          delivery_date: undefined,
          statistical_delivery_date: undefined,
          justification: "",
        },
      ],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
    rules: { minLength: 1 },
  });

  const headerFields = useMemo(() => zodKeys(itemSchema), []);

  type FieldType = (typeof fields)[0];

  const dateFormat = "yyyy-mm-dd"; // ISO 8601,
  const parseRaw = true;

  const handleFileUpload = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      const file = e.target.files?.[0];
      if (!file) return;

      setExcelLoading(true);

      const reader = new FileReader();

      reader.onload = (event: ProgressEvent<FileReader>): void => {
        const workbook = XLSX.read(event.target?.result as string, {
          type: "binary",
          cellDates: true,
          dateNF: dateFormat,
          raw: parseRaw,
          dense: true,
          codepage: 65001,
        });

        // const { headerValues, data } = selectHeader(mapWorkbook(workbook), [0]);
        // console.log(parseSheetData(headerValues, data));

        setValue(
          "items",
          parseSheetData(headerFields, mapWorkbook(workbook).slice(1)),
        );
        e.target.value = "";
        setExcelLoading(false);
      };

      reader.readAsBinaryString(file);
    },
    [],
  );

  const onSubmit = useCallback(
    (formData: any) => {
      setSubmitLoading(true);
      console.log("Form Data:", formData);
      formDataRef.current = formData;

      try {
        // Abrir uma nova aba para autenticação
        const authWindow = window.open(
          "/microsoft_auth_tokens/auth?scope=procurement",
          "_blank",
          "width=600,height=600",
        );

        if (!listenerAdded) {
          // Listener para verificar o resultado da autenticação
          window.addEventListener("message", (event) => {
            if (event.data.authenticated) {
              massUpdateByApiDeliveryTerms(formDataRef.current.items)
                .then(() => {
                  // navigate(`/vbuyer/new_delivery_terms`);
                  enqueueSnackbar("Solicitação em processamento!", {
                    variant: "success",
                  });

                  setValue("items", []);
                  setSubmitLoading(false);
                })
                .catch((error) => {
                  console.log(error);
                  enqueueSnackbar("Erro ao processar solicitação!", {
                    variant: "error",
                  });
                  setSubmitLoading(false);
                });
            } else {
              enqueueSnackbar(
                "Autenticação falhou. Por favor, tente novamente.",
                {
                  variant: "warning",
                },
              );
              setSubmitLoading(false);
            }
          });

          setListenerAdded(true);
        }
      } catch (error) {
        console.error("Erro ao autenticar:", error);
        enqueueSnackbar("Erro ao autenticar. Por favor, tente novamente.", {
          variant: "error",
        });
        setSubmitLoading(false);
      }
    },
    [listenerAdded],
  );

  console.log("Fields:", fields);

  return (
    <Container
      sx={{
        py: 3,
        minHeight: "100%",
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        sx={{
          border: "1px solid #d9d9d9",
          width: "100%",
          height: "100%",
        }}
      >
        <CardHeader title="Update Delivery/Statistical date" />
        <CardContent>
          <input
            accept=".xlsx, .xls"
            style={{ display: "none" }}
            id="file-upload"
            type="file"
            onChange={handleFileUpload}
          />
          <label htmlFor="file-upload">
            <Button
              variant="contained"
              color="primary"
              component="span"
              startIcon={<Icon path={mdiUpload} size={1} />}
            >
              Upload File
            </Button>
          </label>

          <Box sx={{ mt: 2 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Purchase Order</TableCell>
                    <TableCell>Item</TableCell>
                    <TableCell>Schedule Line</TableCell>
                    <TableCell>Delivery Date</TableCell>
                    <TableCell>Statistical Date</TableCell>
                    <TableCell>Justification</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {excelLoading ? (
                    <TableRow key={1}>
                      {Array.from(Array(headerFields.length + 1), (index) => (
                        <TableCell key={index}>
                          <Skeleton />
                        </TableCell>
                      ))}
                    </TableRow>
                  ) : (
                    fields.map((field, index) => (
                      <TableRow key={field.id}>
                        <TableCell>
                          <Controller
                            name={`items.${index}.purchase_order`}
                            control={control}
                            render={({ field: { value, ...field } }) => (
                              <TextField
                                {...field}
                                defaultValue={value || ""}
                                variant="outlined"
                                fullWidth
                                size="small"
                                inputProps={{
                                  inputMode: "numeric",
                                  pattern: "\\d{10,10}",
                                  maxLength: 10,
                                }}
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell>
                          <Controller
                            name={`items.${index}.purchase_order_item`}
                            control={control}
                            render={({ field: { value, ...field } }) => (
                              <TextField
                                {...field}
                                defaultValue={value || ""}
                                variant="outlined"
                                fullWidth
                                size="small"
                                inputProps={{
                                  inputMode: "numeric",
                                  pattern: "\\d{1,10}",
                                  maxLength: 10,
                                }}
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell>
                          <Controller
                            name={`items.${index}.schedule_line`}
                            control={control}
                            render={({ field: { value, ...field } }) => (
                              <TextField
                                {...field}
                                defaultValue={value || "10"}
                                variant="outlined"
                                fullWidth
                                size="small"
                                inputProps={{
                                  inputMode: "numeric",
                                  pattern: "\\d{1,10}",
                                  maxLength: 10,
                                }}
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell>
                          <Controller
                            name={`items.${index}.delivery_date`}
                            control={control}
                            render={({ field: { value, ...field } }) => (
                              <DatePicker
                                {...field}
                                defaultValue={value ? dayjs(value) : undefined}
                                slotProps={{
                                  textField: {
                                    variant: "outlined",
                                    fullWidth: true,
                                    size: "small",
                                  },
                                }}
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell>
                          <Controller
                            name={`items.${index}.statistical_delivery_date`}
                            control={control}
                            render={({ field: { value, ...field } }) => (
                              <DatePicker
                                {...field}
                                defaultValue={value ? dayjs(value) : undefined}
                                slotProps={{
                                  textField: {
                                    variant: "outlined",
                                    fullWidth: true,
                                    size: "small",
                                  },
                                }}
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell>
                          <Controller
                            name={`items.${index}.justification`}
                            control={control}
                            render={({ field: { value, ...field } }) => (
                              <TextField
                                {...field}
                                multiline
                                rows={1}
                                defaultValue={value || ""}
                                variant="outlined"
                                fullWidth
                                size="small"
                                inputProps={{
                                  maxLength: 5000,
                                }}
                              />
                            )}
                          />
                        </TableCell>

                        <TableCell>
                          <Button
                            variant="contained"
                            color="error"
                            onClick={() => remove(index)}
                            startIcon={<Icon path={mdiTrashCan} size={1} />}
                          >
                            Remove
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                  <TableRow key="addRow">
                    <TableCell colSpan={headerFields.length + 1} align="center">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          append({
                            purchase_order: "",
                            purchase_order_item: "",
                            schedule_line: 1,
                            delivery_date: undefined,
                            statistical_delivery_date: undefined,
                            justification: "",
                          })
                        }
                        startIcon={<Icon path={mdiPlus} size={1} />}
                      >
                        Add Row
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>

              <Box sx={{ mt: 2 }}>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  color="success"
                  endIcon={<Icon path={mdiSend} size={1} />}
                  loading={submitLoading}
                  disabled={fields.length == 0}
                >
                  Submit
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default DeliveryTerms;
