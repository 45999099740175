import {
  mdiAlert,
  mdiCheckboxMarkedCircle,
  mdiChevronDown,
  mdiMedal,
} from "@mdi/js";
import Icon from "@mdi/react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Field, Form, Formik } from "formik";
import i18next from "i18next";
import { enqueueSnackbar } from "notistack";
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Vendor } from "../../../@types/services";
import ProposalLine from "../../../@types/services/ProposalLine";
import PurchaseOrderType from "../../../@types/services/PurchaseOrderType";
import ServiceNegotiation from "../../../@types/services/ServiceNegotiation";
import getPurchaseOrderType from "../../../services/purchaseOrderTypes";
import { defineWinner } from "../../../services/serviceNegotiations";
import DownloadProposals from "../DownloadProposals";

interface FormValues {
  justification: string;
  rfq_info: number | "";
  award_documents?: File[];
}

type ModalAwardProps = {
  serviceNegotiationId: number;
  openConfirmationModal: boolean;
  setOpenConfirmationModal: Dispatch<SetStateAction<boolean>>;
};

const ModalAward: FC<ModalAwardProps> = ({
  serviceNegotiationId,
  openConfirmationModal,
  setOpenConfirmationModal,
}) => {
  const [loading, setLoading] = useState(true);
  const [purchaseOrderTypes, setPurchaseOrderTypes] = useState<
    PurchaseOrderType[]
  >([]);

  const handleFetchPurchaseOrderType = useCallback(async () => {
    getPurchaseOrderType().then((resource) => {
      setPurchaseOrderTypes(resource);
    });
  }, []);

  const initialValues: FormValues = {
    justification: "",
    rfq_info: "",
  };

  useEffect(() => {
    handleFetchPurchaseOrderType();
  }, [handleFetchPurchaseOrderType]);

  return (
    <Dialog
      key={serviceNegotiationId}
      open={openConfirmationModal}
      onClose={() => setOpenConfirmationModal(false)}
      fullWidth
      maxWidth="lg"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          if (!values.award_documents) {
            return;
          }

          defineWinner(serviceNegotiationId, values)
            .then(() => {
              enqueueSnackbar("Proposta ganhadora aceita com sucesso!");
              window.location.href = window.location.href;
            })
            .catch(() => {
              enqueueSnackbar("Não foi possível definir ganhador!");
            })
            .finally(() => {
              setLoading(() => false);
            });
          setOpenConfirmationModal(false);
        }}
      >
        {({ values, handleChange, handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <DialogTitle>
              {i18next.t("serviceNegotiation.dialogWinner.confirmWinner")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {i18next.t("serviceNegotiation.dialogWinner.questionConfirm")}
              </DialogContentText>
              <br />
              <div style={{ display: "flex", gap: "5px" }}>
                <FormControl fullWidth margin="dense">
                  <InputLabel id="purchaseOrderTypeLabel">
                    {i18next.t("serviceNegotiation.dialogWinner.typeOfRfq")}
                  </InputLabel>
                  <Select
                    id="rfq_info"
                    name="rfq_info"
                    label="tipo de ordem de compra"
                    labelId="select-label"
                    required
                    value={values.rfq_info}
                    onChange={handleChange}
                  >
                    {purchaseOrderTypes.map((type) => (
                      <MenuItem key={type.id} value={type.id}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth margin="dense">
                  <Field
                    name="award_documents"
                    type="file"
                    label={i18next.t("serviceQuotationApproval.awardDocuments")}
                    component={TextField}
                    onChange={(event: {
                      currentTarget: { files: (FileList | undefined)[] };
                    }) => {
                      const { files } = event.currentTarget;
                      handleChange(event);
                      setFieldValue("award_documents", files);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{ multiple: true }}
                    required
                  />
                </FormControl>
              </div>
              <br />
              <TextField
                multiline
                rows={3}
                name="justification"
                label={i18next.t(
                  "serviceNegotiation.dialogWinner.justification",
                )}
                value={values.justification}
                onChange={handleChange}
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setOpenConfirmationModal(false)}
                color="primary"
              >
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Confirm
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

type ServiceNegotiationLineProps = {
  serviceNegotiation: ServiceNegotiation;
  vendorWinner?: Vendor;
};

const ServiceNegotiationLine: FC<ServiceNegotiationLineProps> = ({
  serviceNegotiation,
  vendorWinner,
}) => {
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  return (
    <Accordion key={serviceNegotiation.id}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          alignItems: "center",
          "& > *": {
            m: 1,
          },
        }}
      >
        <Grid2
          container
          spacing={2}
          sx={{ width: "100%", alignItems: "center", justifyContent: "center" }}
        >
          <Grid2 xs={5}>{serviceNegotiation.vendor.fullvendorname}</Grid2>
          <Grid2
            xs={4}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {serviceNegotiation.proposal && (
              <DownloadProposals proposal={serviceNegotiation.proposal} />
            )}
            {vendorWinner &&
              vendorWinner.id === serviceNegotiation.vendor.id && (
                <Tooltip
                  title={i18next.t("serviceQuotation.tooltip.vendorWinner")}
                  placement="top"
                  sx={{
                    fontSize: "2rem",
                  }}
                >
                  <Icon path={mdiCheckboxMarkedCircle} size={1} color="green" />
                </Tooltip>
              )}
          </Grid2>
          <Grid2
            xs={2}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              {serviceNegotiation.status?.name === "Waiting Bid" ? (
                <ButtonGroup
                  size="small"
                  aria-label="Small button group"
                  sx={{ marginLeft: "10px" }}
                >
                  {!serviceNegotiation.vendor.sap_code ? (
                    <Box
                      sx={{
                        flexGrow: 1,
                        padding: "0px 20px",
                        margin: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                      }}
                    >
                      <Tooltip
                        title={i18next.t("warning.noSapCodeForVendor")}
                        placement="top"
                        arrow
                      >
                        <Chip
                          label={
                            <span style={{ fontSize: "1.1rem" }}>
                              <Icon path={mdiAlert} size={1} />
                            </span>
                          }
                          color="warning"
                          sx={{
                            padding: "5px 5px 0px 5px",
                          }}
                        />
                      </Tooltip>
                    </Box>
                  ) : (
                    <Tooltip placement="top" title={i18next.t("selectAward")}>
                      <Button
                        color="success"
                        onClick={() => setOpenConfirmationModal(true)}
                        disabled={!serviceNegotiation.proposal}
                      >
                        <Icon path={mdiMedal} size={0.6} />
                        {i18next.t("award")}
                      </Button>
                    </Tooltip>
                  )}
                </ButtonGroup>
              ) : (
                <Typography align="center">
                  {serviceNegotiation.status?.title}
                </Typography>
              )}
            </div>
          </Grid2>
        </Grid2>

        <AccordionSummary
          expandIcon={<Icon path={mdiChevronDown} size={0.8} />}
          aria-controls="panel1-content"
          id="panel1-header"
          disabled={
            !serviceNegotiation.proposal &&
            serviceNegotiation.status?.name !== "Rejected By Vendor"
          }
        />
      </Box>
      <ModalAward
        serviceNegotiationId={serviceNegotiation.id}
        openConfirmationModal={openConfirmationModal}
        setOpenConfirmationModal={setOpenConfirmationModal}
      />
      <Divider />
      <AccordionDetails>
        {serviceNegotiation.proposal && (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                mb: 1,
              }}
            >
              <Chip
                label={`${i18next.t("newProposal.deliveryDate")}: ${i18next.t(
                  "dateTime",
                  {
                    val: Date.parse(serviceNegotiation.proposal.delivery_date),
                    interpolation: { escapeValue: false },
                    formatParams: {
                      val: {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                        timeZone: "UTC",
                        // hour: "false",
                        // minute: "false",
                        // second: "false",
                        // hour12: false,
                      },
                    },
                  },
                )}`}
              />
              <Chip
                label={`${i18next.t("newProposal.createdAt")}: ${i18next.t(
                  "dateTime",
                  {
                    val: Date.parse(serviceNegotiation.proposal.created_at),
                    interpolation: { escapeValue: false },
                    formatParams: {
                      val: {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                        // hour: "false",
                        // minute: "false",
                        // second: "false",
                        // hour12: false,
                      },
                    },
                  },
                )}`}
              />
              <Chip
                label={`${i18next.t("newProposal.updatedAt")}: ${i18next.t(
                  "dateTime",
                  {
                    val: Date.parse(
                      String(serviceNegotiation.proposal.updated_at),
                    ),
                    interpolation: { escapeValue: false },
                    formatParams: {
                      val: {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                        // hour: "false",
                        // minute: "false",
                        // second: "false",
                        // hour12: false,
                      },
                    },
                  },
                )}`}
              />
            </Box>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {" "}
                      {i18next.t("serviceNegotiation.shortDescription")}{" "}
                    </TableCell>
                    <TableCell align="right">
                      {" "}
                      {i18next.t("newProposal.quantity")}{" "}
                    </TableCell>
                    <TableCell align="right">
                      {i18next.t("newProposal.measureUnit")}{" "}
                    </TableCell>
                    <TableCell align="right">
                      {" "}
                      {i18next.t("serviceNegotiation.grossPrice")}{" "}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {serviceNegotiation.proposal.proposal_lines &&
                    serviceNegotiation.proposal.proposal_lines
                      .sort((a, b) => (a.position || 0) - (b.position || 0))
                      .map((proposalLine: ProposalLine) => (
                        <TableRow
                          key={proposalLine.id}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell>{proposalLine.short_text}</TableCell>
                          <TableCell align="right">
                            {proposalLine.quantity}
                          </TableCell>
                          <TableCell align="right">
                            {proposalLine.measure_unit?.name}
                          </TableCell>
                          <TableCell align="right">
                            {serviceNegotiation.proposal &&
                              serviceNegotiation.proposal.currency.unit}{" "}
                            {proposalLine.gross_price}
                          </TableCell>
                        </TableRow>
                      ))}
                  <TableRow
                    key={serviceNegotiation.proposal.id}
                    sx={{
                      justifyContent: "space-between",
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell />
                    <TableCell />
                    <TableCell align="right">
                      <strong>Total</strong>
                    </TableCell>
                    <TableCell align="right">
                      {serviceNegotiation.proposal.currency.unit}{" "}
                      {serviceNegotiation.proposal.price}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
        {serviceNegotiation?.status?.name === "Rejected By Vendor" &&
          serviceNegotiation.service_negotiation_rejects &&
          serviceNegotiation.service_negotiation_rejects.length > 0 && (
            <Card>
              <Alert severity="info">
                {`${i18next.t("serviceNegotiation.reject.reason")}: ${i18next.t(
                  `serviceRejectionReasons.${
                    serviceNegotiation.service_negotiation_rejects.slice(-1)[0]
                      .reason
                  }`,
                )}`}
              </Alert>

              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {i18next.t(`serviceNegotiation.reject.comment`)}
                </Typography>

                <Typography variant="body2" color="textSecondary">
                  {
                    serviceNegotiation.service_negotiation_rejects.slice(-1)[0]
                      .comment
                  }
                </Typography>
              </CardContent>
            </Card>
          )}
      </AccordionDetails>
    </Accordion>
  );
};

type ServiceNegotiationTableProps = {
  serviceNegotiations: ServiceNegotiation[];
};

const ServiceNegotiationTable: FC<ServiceNegotiationTableProps> = ({
  serviceNegotiations,
}) => {
  return (
    <div>
      {serviceNegotiations && serviceNegotiations?.length > 0 ? (
        serviceNegotiations
          .sort((a, b) => {
            const priceA = a.proposal?.price ?? null;
            const priceB = b.proposal?.price ?? null;

            if (priceA === null && priceB === null) return 0;
            else if (priceA === null) return 1;
            else if (priceB === null) return -1;
            return priceA - priceB;
          })
          .map((serviceNegotiation: ServiceNegotiation) => (
            <ServiceNegotiationLine
              key={serviceNegotiation.id}
              serviceNegotiation={serviceNegotiation}
              vendorWinner={serviceNegotiation.service_quotation.vendor_winner}
            />
          ))
      ) : (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
            alignItems: "center",
            "& > *": {
              m: 1,
            },
          }}
        >
          <Chip label={<span>{i18next.t("noInvitedVendor")}</span>} />
        </Box>
      )}
    </div>
  );
};

export default ServiceNegotiationTable;
