import MmRequestType from "../@types/services/MmRequest";
import api from "./api";

export type MaterialsFilter = {
  matnumber: string;
};

interface MaterialsFetch {
  page?: number;
  itemsPerPage?: number;
  filters?: MaterialsFilter;
}

export const fetchQuantityOfMaterialsOptions = async () => {
  const response = await api.get(`/mm_requests/quantity_of_materials_options`, {
    baseURL: "/vbuyer/api/v2",
  });
  return response.data;
};

export const fetchRequesterFieldOptions = async () => {
  const response = await api.get(`/mm_requests/requester_field_options`, {
    baseURL: "/vbuyer/api/v2",
  });
  return response.data;
};

export const fetchMaterials = async ({
  page = 1,
  itemsPerPage = 25,
  filters,
}: MaterialsFetch) => {
  const response = await api.get(`/mm_requests/search_material_number`, {
    baseURL: "/vbuyer/api/v2",
    params: {
      page,
      items_per_page: itemsPerPage,
      ...filters,
    },
  });
  return response.data;
};

export const fetchMmUsers = async ({
  page = 1,
  itemsPerPage = 25,
  filters,
}: MaterialsFetch) => {
  const response = await api.get(`/mm_requests/search_user_of_mm`, {
    baseURL: "/vbuyer/api/v2",
    params: {
      page,
      items_per_page: itemsPerPage,
      ...filters,
    },
  });
  return response.data;
};

export const createMmRequest = async (mmRequest: MmRequestType) => {
  console.log({ duimp_request: { mm_request_attributes: mmRequest } });
  const response = await api.post(
    `/duimp_requests`,
    { duimp_request: { mm_request_attributes: mmRequest } },
    {
      baseURL: "/vbuyer/api/v2",
    },
  );
  return response.data;
};
