/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
import { mdiDeleteOutline, mdiPlusBox } from "@mdi/js";
import Icon from "@mdi/react";
import { LoadingButton } from "@mui/lab";
import {
  Card,
  Container,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { Field, FieldArray, Form, Formik, FormikErrors } from "formik";
import i18next from "i18next";
import { enqueueSnackbar } from "notistack";
import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import Currency from "../../@types/services/Currency";
import MeasureUnit from "../../@types/services/MeasureUnit";
import Proposal from "../../@types/services/Proposal";
import ProposalLine from "../../@types/services/ProposalLine";
import ServiceNegotiation from "../../@types/services/ServiceNegotiation";
import NumericFormatField from "../../components/atoms/NumericFormatField";
import LoadingCentral from "../../components/molecules/LoadingCentral";
import getCurrencies from "../../services/currency";
import getMeasureUnits, {
  MeasureUnitsFetch,
} from "../../services/measureUnits";
import createProposal from "../../services/proposals";
import { getServiceNegotiation } from "../../services/serviceNegotiations";

const ProposalLineSchema = z.object({
  short_text: z.string(),
  quantity: z.coerce.number().min(1),
  gross_price: z.coerce.number().min(1),
});

const ProposalSchema = z.object({
  delivery_date: z.string().date(),
  proposal_lines: z.array(ProposalLineSchema),
});

const NewProposal: FC = () => {
  const { serviceNegotiationId } = useParams();
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [serviceNegotiation, setServiceNegotiation] =
    useState<ServiceNegotiation>();
  const [proposalLines, setProposalLines] = useState<ProposalLine[]>();
  const [measureUnits, setMeasureUnits] = useState<MeasureUnit[]>([]);
  const [currencies, setCurrencies] = useState<Currency[]>([]);
  const navigate = useNavigate();

  const resetInputOrder = (proposalLines: ProposalLine[]) => {
    return proposalLines.map((line, index) => ({
      ...line,
      position: (index + 1) * 10,
    }));
  };

  const handleFetchMeasureUnits = useCallback(
    async (names: MeasureUnitsFetch) => {
      getMeasureUnits(names).then((resource) => {
        setMeasureUnits(resource);
      });
    },
    [],
  );

  const handleFetchCurrencies = useCallback(async () => {
    getCurrencies().then((resource) => {
      setCurrencies(resource);
    });
  }, []);

  const handleFetchServiceNegotiation = useCallback(async (id: number) => {
    setLoading(() => true);
    await getServiceNegotiation(id)
      .then((resource) => {
        setServiceNegotiation(() => resource);
        setProposalLines(() =>
          resource.service_quotation.service_lines.map((line, index) => ({
            ...line,
            position: line.service_line_number_ext,
          })),
        );
      })
      .catch(() => {
        setNotFound(() => true);
      })
      .finally(() => {
        setLoading(() => false);
      });
  }, []);

  useEffect(() => {
    if (!serviceNegotiationId) {
      setNotFound(() => true);
      return;
    }

    handleFetchMeasureUnits({ names: ["DAY", "MON", "LE", "EA", "H", "PC"] });
    handleFetchCurrencies();
    handleFetchServiceNegotiation(parseInt(serviceNegotiationId, 10));
  }, [
    handleFetchServiceNegotiation,
    serviceNegotiationId,
    handleFetchMeasureUnits,
    handleFetchCurrencies,
  ]);

  const initialValues: Proposal = {
    id: "",
    delivery_date: "",
    proposal_lines: proposalLines || [],
    currency: {
      id: 0,
      created_at: "",
      updated_at: "",
      name: "",
      unit: "",
    },
    created_at: "",
    updated_at: "",
    price: 0,
  };

  if (!serviceNegotiation) {
    return <LoadingCentral />;
  }

  return (
    <Container
      sx={{
        minHeight: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        sx={{
          display: "flex",
          border: "1px solid #d9d9d9",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={toFormikValidationSchema(ProposalSchema)}
          onSubmit={(values) => {
            setLoading(() => true);
            if (
              !serviceNegotiationId ||
              (!values.technicalProposal && !values.commercialProposal)
            ) {
              return;
            }

            createProposal(values, serviceNegotiationId).then(() => {
              navigate(`/vbuyer/service_negotiations/${serviceNegotiationId}`);
              enqueueSnackbar("Proposta enviada com sucesso!");
            });
          }}
        >
          {({ values, errors, handleChange, handleSubmit, setFieldValue }) => {
            console.log(errors);
            return (
              <Form
                onSubmit={handleSubmit}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  borderRadius: "3px",
                  padding: "20px",
                  marginBottom: "10px",
                }}
              >
                <h2>{i18next.t("newProposal.title")}</h2>

                <h3 style={{ width: "100%" }}>
                  {i18next.t("newProposal.serviceQuotation")}
                  {" #"}
                  {serviceNegotiation?.service_quotation?.identifier}
                </h3>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "10px",
                    width: "100%",
                    marginBottom: "10px",
                  }}
                >
                  <TextField
                    label={i18next.t("newProposal.name")}
                    id="filled-size-small"
                    variant="outlined"
                    size="small"
                    sx={{ width: "100%" }}
                    value={
                      serviceNegotiation?.service_quotation?.short_description
                    }
                    disabled
                  />
                  <TextField
                    sx={{ width: "100%" }}
                    id="filled-size-small"
                    name="delivery_date"
                    label={i18next.t("newProposal.deliveryDate")}
                    type="date"
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleChange}
                    error={!!errors.delivery_date}
                    helperText={errors.delivery_date}
                    required
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    height: "35px",
                    margin: "20px 0px",
                  }}
                >
                  <FormControl style={{ width: "20%", marginRight: "5px" }}>
                    <InputLabel id="select-label">
                      {i18next.t("newProposal.currency")}
                    </InputLabel>
                    <Field
                      as={Select}
                      name="currency"
                      labelId="select-label"
                      id="select"
                      label="currency"
                      onChange={handleChange}
                      noValidate
                      autoComplete="off"
                      required
                    >
                      {currencies.map((cur) => (
                        <MenuItem key={cur.id} value={cur.id}>
                          {` ${cur.name} - `}
                          {cur.unit}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                  <div style={{ display: "flex", gap: "5px" }}>
                    <Field
                      name="technicalProposal"
                      type="file"
                      label={i18next.t("newProposal.technicalProposal")}
                      component={TextField}
                      onChange={(event: {
                        currentTarget: { files: (FileList | undefined)[] };
                      }) => {
                        const file = event.currentTarget.files[0];
                        handleChange(event);
                        setFieldValue("technicalProposal", file);
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{ multiple: false }}
                      required
                    />

                    <Field
                      name="commercialProposal"
                      type="file"
                      label={i18next.t("newProposal.commercialProposal")}
                      component={TextField}
                      onChange={(event: {
                        currentTarget: { files: (FileList | undefined)[] };
                      }) => {
                        const file = event.currentTarget.files[0];
                        handleChange(event);
                        setFieldValue("commercialProposal", file); // Certifique-se de ter acesso a setFieldValue
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{ multiple: false }}
                      required
                    />
                  </div>
                </div>

                <FieldArray name="proposal_lines">
                  {({ insert, remove, push }) => (
                    <div>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell align="center" sx={{ width: "40%" }}>
                                {i18next.t("newProposal.shortText")}
                              </TableCell>
                              <TableCell align="center">
                                {i18next.t("newProposal.quantity")}
                              </TableCell>
                              <TableCell align="center">
                                {i18next.t("newProposal.measureUnit")}
                              </TableCell>
                              <TableCell align="center">
                                {i18next.t("newProposal.price")}
                              </TableCell>
                              <TableCell align="center"> # </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {values.proposal_lines.map((line, index) => (
                              <TableRow key={line.id}>
                                <TableCell sx={{ width: "40%" }}>
                                  <Tooltip
                                    title={
                                      (
                                        errors.proposal_lines as FormikErrors<ProposalLine>[]
                                      )?.[index]?.short_text
                                    }
                                    placement="top"
                                    arrow
                                  >
                                    <TextField
                                      sx={{ width: "100%" }}
                                      name={`proposal_lines[${index}].short_text`}
                                      size="small"
                                      inputProps={{ maxLength: 40 }}
                                      defaultValue={line.short_text}
                                      onChange={handleChange}
                                      error={
                                        !!(
                                          errors.proposal_lines as FormikErrors<ProposalLine>[]
                                        )?.[index]?.short_text
                                      }
                                      required
                                    />
                                  </Tooltip>
                                </TableCell>
                                <TableCell>
                                  <Tooltip
                                    title={
                                      (
                                        errors.proposal_lines as FormikErrors<ProposalLine>[]
                                      )?.[index]?.quantity
                                    }
                                    placement="top"
                                    arrow
                                  >
                                    <TextField
                                      type="number"
                                      name={`proposal_lines[${index}].quantity`}
                                      size="small"
                                      inputProps={{ step: 0.01, min: 0.01 }}
                                      defaultValue={line.quantity}
                                      onChange={handleChange}
                                      error={
                                        !!(
                                          errors.proposal_lines as FormikErrors<ProposalLine>[]
                                        )?.[index]?.quantity
                                      }
                                      required
                                    />
                                  </Tooltip>
                                </TableCell>
                                <TableCell>
                                  <FormControl style={{ width: "100%" }}>
                                    <InputLabel id="select-label">
                                      {i18next.t("newProposal.measureUnit")}
                                    </InputLabel>
                                    <Field
                                      as={Select}
                                      name={`proposal_lines[${index}].measure_unit`}
                                      size="small"
                                      labelId="select-label"
                                      id={`proposal_lines[${index}].measure_unit`}
                                      label="measure_unit"
                                      value={
                                        line?.measure_unit?.id ||
                                        line?.measure_unit
                                      }
                                      onChange={handleChange}
                                      required
                                    >
                                      {measureUnits.map((unit) => (
                                        <MenuItem key={unit.id} value={unit.id}>
                                          {unit.name}
                                        </MenuItem>
                                      ))}
                                    </Field>
                                  </FormControl>
                                </TableCell>
                                <TableCell>
                                  <Tooltip
                                    title={
                                      (
                                        errors.proposal_lines as FormikErrors<ProposalLine>[]
                                      )?.[index]?.gross_price
                                    }
                                    placement="top"
                                    arrow
                                  >
                                    <TextField
                                      name={`proposal_lines[${index}].gross_price`}
                                      size="small"
                                      defaultValue={line.gross_price}
                                      onChange={handleChange}
                                      InputProps={{
                                        inputComponent:
                                          NumericFormatField as any,
                                      }}
                                      error={
                                        !!(
                                          errors.proposal_lines as FormikErrors<ProposalLine>[]
                                        )?.[index]?.gross_price
                                      }
                                      required
                                    />
                                  </Tooltip>
                                </TableCell>
                                <TableCell>
                                  <Tooltip placement="right" title="Delete">
                                    <IconButton
                                      color="error"
                                      onClick={() => {
                                        remove(index);
                                        setFieldValue(
                                          "proposal_lines",
                                          resetInputOrder(
                                            values.proposal_lines.filter(
                                              (_, idx) => idx !== index,
                                            ),
                                          ),
                                        );
                                      }}
                                      style={{
                                        marginLeft: "8px",
                                        fontSize: "0.7rem",
                                      }}
                                    >
                                      <Icon path={mdiDeleteOutline} size={1} />
                                    </IconButton>
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                          <TableFooter>
                            <TableRow>
                              <TableCell align="center" colSpan={5}>
                                <Tooltip
                                  placement="top"
                                  title={i18next.t(
                                    "newProposal.button.addNewDetailLine",
                                  )}
                                >
                                  <LoadingButton
                                    onClick={() =>
                                      push({
                                        position:
                                          (values.proposal_lines.length + 1) *
                                          10,
                                      })
                                    }
                                    style={{
                                      marginTop: "8px",
                                    }}
                                    size="small"
                                    color="info"
                                    variant="contained"
                                    loading={loading}
                                    loadingPosition="center"
                                  >
                                    <Icon path={mdiPlusBox} size={1} />
                                  </LoadingButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          </TableFooter>
                        </Table>
                      </TableContainer>

                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          marginTop: "10px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <LoadingButton
                          type="submit"
                          style={{
                            marginTop: "8px",
                            justifyContent: "center",
                          }}
                          color="success"
                          variant="contained"
                          loading={loading}
                          loadingPosition="center"
                        >
                          {i18next.t("newProposal.button.send")}
                        </LoadingButton>
                      </div>
                    </div>
                  )}
                </FieldArray>
              </Form>
            );
          }}
        </Formik>
      </Card>
    </Container>
  );
};

export default NewProposal;
