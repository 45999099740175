import DuimpRequest from "../@types/services/DuimpRequest";
import api from "./api";

export const createDuimpRequest = async (duimpRequest: DuimpRequest) => {
  const response = await api.post(
    `/duimp_requests`,
    { duimp_request: { mm_request_attributes: duimpRequest } },
    {
      baseURL: "/vbuyer/api/v2",
    },
  );
  return response.data;
};
