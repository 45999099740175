import { mdiCheck, mdiClose, mdiPencil } from "@mdi/js";
import Icon from "@mdi/react";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  TextField,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { FC, useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { User } from "../../../@types/services";
import ServiceQuotation from "../../../@types/services/ServiceQuotation";
import { updateAssignToServiceUser } from "../../../services/serviceQuotations";
import { fetchUsers, UsersFilter } from "../../../services/users";

interface FormData {
  assigned_to_id: number;
}

type AssignToServiceProps = {
  service_quotation: ServiceQuotation;
  handleFetchServiceQuotation: (id: number) => void;
};

const AssignToService: FC<AssignToServiceProps> = ({
  service_quotation,
  handleFetchServiceQuotation,
}) => {
  const [users, setUsers] = useState<User[]>([]);
  const [isEdit, setIsEdit] = useState(false);
  const [loadingAssignTo, setLoadingAssignTo] = useState(false);

  const { control, handleSubmit } = useForm<FormData>({
    defaultValues: {
      assigned_to_id: service_quotation.assigned_to?.id,
    },
  });

  const handleFetchUsers = useCallback(async (filters?: UsersFilter) => {
    await fetchUsers({
      filters: {
        email: filters?.email,
        purchase_group: service_quotation.pr_item.purchase_group.id,
        user_group_name: "Service Module as Buyer",
      },
    }).then((users) => {
      setUsers(users);
    });
  }, []);

  useEffect(() => {
    handleFetchUsers();
  }, [handleFetchUsers]);

  const onSubmit = (data: FormData) => {
    setLoadingAssignTo(() => true);
    updateAssignToServiceUser(service_quotation.id, {
      assigned_to_id: data?.assigned_to_id,
    })
      .then(() => {
        enqueueSnackbar("Responsável alterado com sucesso!");
        handleFetchServiceQuotation(service_quotation.id);
        setIsEdit(false);
      })
      .catch(() => {
        enqueueSnackbar("Não foi possível alterar o responsável!");
      })
      .finally(() => {
        setLoadingAssignTo(() => false);
      });
  };

  return (
    <>
      {!isEdit ? (
        <Box onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            {service_quotation?.assigned_to?.full_name_with_login || ""}

            {!service_quotation.purchase_order_item && (
              <Button
                color="primary"
                variant="contained"
                disableElevation
                onClick={() => setIsEdit(true)}
              >
                <Icon path={mdiPencil} size={0.8} />
              </Button>
            )}
          </Box>
        </Box>
      ) : (
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{ width: "auto", margin: "auto" }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <FormControl fullWidth>
              <Controller
                name="assigned_to_id"
                control={control}
                render={({ field: { onChange, value, ...field } }) => (
                  <Autocomplete
                    {...field}
                    options={users}
                    loading={users.length === 0}
                    defaultValue={service_quotation.assigned_to}
                    getOptionLabel={(option) =>
                      option.full_name_with_login || ""
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(event, newValue) => {
                      onChange(newValue?.id || null);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        key={params.inputProps.id}
                        size="small"
                      />
                    )}
                  />
                )}
              />
            </FormControl>
            <LoadingButton
              type="submit"
              variant="contained"
              color="primary"
              size="small"
              loading={loadingAssignTo}
              loadingPosition="center"
              disableElevation
            >
              <Icon path={mdiCheck} size={1} />
            </LoadingButton>
            <Button
              variant="contained"
              color="error"
              size="small"
              onClick={() => setIsEdit(false)}
              disabled={loadingAssignTo}
              disableElevation
            >
              <Icon path={mdiClose} size={1} />
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};

export default AssignToService;
