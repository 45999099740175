import { MainContainer } from "@chatscope/chat-ui-kit-react";
import { mdiDownload } from "@mdi/js";
import Icon from "@mdi/react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  AppBar,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Chip,
  Container,
  Grid,
  OutlinedInput,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import i18next from "i18next";
import { FC, SyntheticEvent, useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Proposal from "../../@types/services/Proposal";
import ProposalLine from "../../@types/services/ProposalLine";
import ServiceNegotiation from "../../@types/services/ServiceNegotiation";
import Chat from "../../components/molecules/Chat";
import DownloadProposals from "../../components/molecules/DownloadProposals";
import ListAttachmentServiceNegotiation from "../../components/molecules/ListAttachmentServiceNegotiation";
import LoadingCentral from "../../components/molecules/LoadingCentral";
import ProgressBar from "../../components/molecules/ProgressBar";
import RejectServiceNegotiation from "../../components/molecules/RejectServiceNegotiation";
import RevertRejection from "../../components/molecules/RevertRejection";
import { fetchProposal } from "../../services/proposals";
import { getServiceNegotiation } from "../../services/serviceNegotiations";
import NotFound from "../notFound";

const ShowServiceNegotiation: FC = () => {
  const { serviceNegotiationId } = useParams();
  const [serviceNegotiation, setServiceNegotiation] =
    useState<ServiceNegotiation>();
  const [proposal, setProposal] = useState<Proposal>();
  const [value, setValue] = useState("0");
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [rejectOpen, setRejectOpen] = useState(false);
  const [revertOpen, setRevertOpen] = useState(false);

  const handleChange = (event: SyntheticEvent, newValue: string): void => {
    setValue(newValue);
  };

  const handleFetchProposal = useCallback(async (id: string) => {
    await fetchProposal(id).then((resource) => {
      setProposal(() => resource);
    });
  }, []);

  const handleFetchServiceNegotiation = useCallback(
    async (id: number) => {
      setLoading(() => true);
      await getServiceNegotiation(id)
        .then((resource) => {
          setServiceNegotiation(() => resource);
          if (resource.proposal) {
            handleFetchProposal(resource.proposal.id);
          }
        })
        .catch(() => {
          setNotFound(() => true);
        })
        .finally(() => {
          setLoading(() => false);
        });
    },
    [handleFetchProposal],
  );

  useEffect(() => {
    if (!serviceNegotiationId) {
      setNotFound(() => true);
      return;
    }

    handleFetchServiceNegotiation(parseInt(serviceNegotiationId, 10));
  }, [handleFetchServiceNegotiation, serviceNegotiationId]);

  if (notFound || !serviceNegotiationId) {
    return <NotFound />;
  }

  if (loading) {
    return <LoadingCentral />;
  }

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        px: 2,
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          padding: "0px 20px",
          display: "flex",
        }}
      >
        <div>
          <h2>{i18next.t("serviceNegotiation.title")}</h2>
          <h3 style={{ marginTop: "-10px" }}>
            #{serviceNegotiation?.service_quotation?.identifier}
          </h3>
        </div>
        {serviceNegotiation?.process_instance && (
          <ProgressBar
            maxSize={4}
            process={serviceNegotiation?.process_instance}
          />
        )}
      </Box>
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar color="gray" position="static">
            <Toolbar variant="dense">
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                {i18next.t("details")}
              </Typography>
              {serviceNegotiation?.service_quotation?.purchase_order_item
                ?.purchase_order &&
                serviceNegotiation?.service_quotation.purchase_order_item
                  .purchase_order.purchase_order_pdf_document?.attachment && (
                  <Button
                    variant="contained"
                    color="success"
                    sx={{ marginRight: 2 }}
                    href={`/vbuyer/purchase_orders/${serviceNegotiation.service_quotation.purchase_order_item.purchase_order.id}/download.pdf`}
                    download={
                      serviceNegotiation.service_quotation.purchase_order_item
                        .purchase_order.purchase_order_pdf_document.attachment
                        .filename
                    }
                    target="_blank"
                    disableElevation
                  >
                    Download Purchase Order
                  </Button>
                )}
              {!serviceNegotiation?.proposal &&
                serviceNegotiation?.status.name != "Rejected By Vendor" && (
                  <Button
                    variant="contained"
                    color="error"
                    sx={{ marginRight: 2 }}
                    onClick={() => {
                      setRejectOpen(true);
                    }}
                  >
                    {i18next.t("reject")}
                  </Button>
                )}
              {serviceNegotiation?.status.name == "Rejected By Vendor" && (
                <Button
                  variant="contained"
                  color="info"
                  sx={{ marginRight: 2 }}
                  onClick={() => {
                    setRevertOpen(true);
                  }}
                >
                  {i18next.t("revertReject")}
                </Button>
              )}
              <ButtonGroup
                sx={{
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid #B4B3B3",
                  backgroundColor: "#fff",
                  borderRadius: "5px",
                }}
                size="small"
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: "#757575",
                    padding: "6px",
                  }}
                >
                  {
                    serviceNegotiation?.service_quotation?.scope_of_work
                      ?.filename
                  }
                </Typography>
                <Button
                  variant="contained"
                  disabled={serviceNegotiation?.status?.name === "Cancelled"}
                  sx={{
                    marginLeft: "8px",
                    borderRadius: "5px",
                  }}
                  href={`/vbuyer/api/v2/service_quotations/${serviceNegotiation?.service_quotation.id}/get_scope_of_work`}
                  download={
                    serviceNegotiation?.service_quotation?.scope_of_work
                      ?.filename
                  }
                  target="_blank"
                >
                  {i18next.t("serviceNegotiation.scopeOfWork")}
                  <span style={{ margin: "4px 0px 0px 0px" }}>
                    <Icon path={mdiDownload} size={0.8} />
                  </span>
                </Button>
              </ButtonGroup>
            </Toolbar>
          </AppBar>
        </Box>
        {serviceNegotiation?.id && (
          <RejectServiceNegotiation
            serviceNegotiationId={serviceNegotiation.id}
            rejectOpen={rejectOpen}
            setRejectOpen={setRejectOpen}
          />
        )}
        {serviceNegotiation?.id && (
          <RevertRejection
            serviceNegotiationId={serviceNegotiation.id}
            revertOpen={revertOpen}
            setRevertOpen={setRevertOpen}
          />
        )}

        <Card variant="outlined" square>
          <CardContent sx={{ p: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography variant="body2">
                  {i18next.t("serviceNegotiation.prNumber")}
                </Typography>
                <Typography variant="subtitle2">
                  {serviceNegotiation?.service_quotation?.pr_item?.pr_number ||
                    ""}
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography variant="body2">
                  {i18next.t("serviceNegotiation.itemNumber")}
                </Typography>
                <Typography variant="subtitle2">
                  {serviceNegotiation?.service_quotation?.pr_item
                    ?.item_number || ""}
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <Typography variant="body2">
                  {i18next.t("serviceNegotiation.requisitionDate")}
                </Typography>
                <Typography variant="subtitle2">
                  {serviceNegotiation?.service_quotation?.pr_item
                    ?.requisition_date
                    ? i18next.t("dateTime", {
                        val: Date.parse(
                          serviceNegotiation?.service_quotation?.pr_item
                            .requisition_date,
                        ),
                        interpolation: { escapeValue: false },
                        formatParams: {
                          val: {
                            year: "numeric",
                            month: "numeric",
                            day: "numeric",
                            timeZone: "UTC",
                            // hour12: false,
                          },
                        },
                      })
                    : ""}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2">
                  {i18next.t("serviceNegotiation.shortDescription")}
                </Typography>
                <Typography variant="subtitle2">
                  {serviceNegotiation?.service_quotation?.short_description ||
                    ""}
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography variant="body2">
                  {i18next.t("serviceNegotiation.plantNumber")}
                </Typography>
                <Typography variant="subtitle2">
                  {serviceNegotiation?.service_quotation?.pr_item?.plant
                    ?.plant_number || ""}
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <Typography variant="body2">
                  {i18next.t("serviceNegotiation.quantity")}
                </Typography>
                <Typography variant="subtitle2">
                  {serviceNegotiation?.service_quotation?.pr_item?.quantity ||
                    ""}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <OutlinedInput
                  id="description"
                  multiline
                  rows={5} // Número de linhas que o TextArea terá
                  placeholder="Description"
                  value={serviceNegotiation?.service_quotation?.long_text || ""}
                  disabled
                  fullWidth
                  style={{
                    marginTop: "16px",
                    fontSize: "0.9em",
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>

      <Container>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="basic tabs example">
              <Tab label={i18next.t("proposal.proposal")} value="0" />
              <Tab label={i18next.t("chat")} value="1" />
              <Tab label={i18next.t("attachments")} value="2" />
            </TabList>
          </Box>
          <TabPanel value="0">
            <Box sx={{ flexGrow: 1 }}>
              <AppBar color="gray" position="static">
                <Toolbar variant="dense">
                  <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    {i18next.t("proposal.proposal")}
                  </Typography>
                  {!loading &&
                    !proposal &&
                    serviceNegotiation?.status?.name === "Waiting Offer" && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          background: "#FFFFFF",
                        }}
                      >
                        <ButtonGroup
                          size="small"
                          aria-label="Small button group"
                          sx={{ "& > *": { mr: 1 } }}
                        >
                          <Button
                            color="primary"
                            variant="contained"
                            component={Link}
                            to={`/vbuyer/service_negotiations/${serviceNegotiationId}/proposals/new`}
                          >
                            {i18next.t("serviceNegotiation.button.newProposal")}
                          </Button>
                        </ButtonGroup>
                      </Box>
                    )}

                  {proposal &&
                    serviceNegotiation?.status?.name === "Waiting Bid" && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          background: "#FFFFFF",
                        }}
                      >
                        <ButtonGroup
                          size="small"
                          aria-label="Small button group"
                          sx={{ "& > *": { mr: 1 } }}
                        >
                          {/* Edit Button */}
                          <Button
                            color="success"
                            variant="contained"
                            sx={{
                              "&:hover": { backgroundColor: "lichenGreen" },
                            }}
                            component={Link}
                            to={`/vbuyer/service_negotiations/${serviceNegotiationId}/proposals/${proposal.id}/edit`}
                          >
                            {i18next.t(
                              "serviceNegotiation.button.editProposal",
                            )}
                          </Button>
                        </ButtonGroup>
                      </Box>
                    )}
                </Toolbar>
              </AppBar>
            </Box>
            <Container
              sx={{
                minHeight: "400px",
                marginTop: "5px",
                padding: "10px",
              }}
            >
              {/* Alocar a proposta atual ou realizar uma lista de propostas */}
              {!proposal && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    my: 2,
                  }}
                >
                  <Chip
                    label={
                      <span>{i18next.t("proposal.createANewProposal")}</span>
                    }
                  />
                </Box>
              )}
              {proposal && <DownloadProposals proposal={proposal} />}
              {proposal && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      my: 2,
                    }}
                  >
                    <Chip
                      label={`${i18next.t("newProposal.deliveryDate")}: ${i18next.t(
                        "dateTime",
                        {
                          val: Date.parse(proposal.delivery_date),
                          interpolation: { escapeValue: false },
                          formatParams: {
                            val: {
                              year: "numeric",
                              month: "numeric",
                              day: "numeric",
                              timeZone: "UTC",
                            },
                          },
                        },
                      )}`}
                    />
                    <Chip
                      label={`${i18next.t("newProposal.createdAt")}: ${i18next.t(
                        "dateTime",
                        {
                          val: Date.parse(String(proposal.created_at)),
                          interpolation: { escapeValue: false },
                          formatParams: {
                            val: {
                              year: "numeric",
                              month: "numeric",
                              day: "numeric",
                              // hour: "false",
                              // minute: "false",
                              // second: "false",
                              // hour12: false,
                            },
                          },
                        },
                      )}`}
                    />
                    <Chip
                      label={`${i18next.t("newProposal.updatedAt")}: ${i18next.t(
                        "dateTime",
                        {
                          val: Date.parse(String(proposal.updated_at)),
                          interpolation: { escapeValue: false },
                          formatParams: {
                            val: {
                              year: "numeric",
                              month: "numeric",
                              day: "numeric",
                              // hour: "false",
                              // minute: "false",
                              // second: "false",
                              // hour12: false,
                            },
                          },
                        },
                      )}`}
                    />
                  </Box>

                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            {" "}
                            {i18next.t(
                              "serviceNegotiation.shortDescription",
                            )}{" "}
                          </TableCell>
                          <TableCell align="right">
                            {" "}
                            {i18next.t("newProposal.quantity")}{" "}
                          </TableCell>
                          <TableCell align="right">
                            {i18next.t("newProposal.measureUnit")}{" "}
                          </TableCell>
                          <TableCell align="right">
                            {" "}
                            {i18next.t("serviceNegotiation.grossPrice")}{" "}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {proposal.proposal_lines &&
                          proposal.proposal_lines.map(
                            (proposalLine: ProposalLine) => (
                              <TableRow
                                key={proposalLine.id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell>{proposalLine.short_text}</TableCell>
                                <TableCell align="right">
                                  {proposalLine.quantity}
                                </TableCell>
                                <TableCell align="right">
                                  {proposalLine.measure_unit?.name}
                                </TableCell>
                                <TableCell align="right">
                                  {proposal.currency.unit}{" "}
                                  {proposalLine.gross_price}
                                </TableCell>
                              </TableRow>
                            ),
                          )}
                        <TableRow
                          key={proposal.id}
                          sx={{
                            justifyContent: "space-between",
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell />
                          <TableCell />
                          <TableCell align="right">
                            <strong>Total</strong>
                          </TableCell>
                          <TableCell align="right">
                            {proposal.currency.unit} {proposal.price}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
            </Container>
          </TabPanel>
          <TabPanel value="1">
            <Container sx={{ minHeight: "400px", marginTop: "5px" }}>
              {/* {serviceQuotation && (
              <ServiceNegotiationChats serviceQuotation={serviceQuotation} />
            )} */}
              <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <MainContainer>
                  {serviceNegotiation && (
                    <Chat
                      chat={serviceNegotiation?.chat}
                      vendor={serviceNegotiation?.vendor}
                    />
                  )}
                </MainContainer>
              </Box>
            </Container>
          </TabPanel>
          <TabPanel value="2">
            <Container sx={{ minHeight: "450px", marginTop: "5px" }}>
              {serviceNegotiation && (
                <ListAttachmentServiceNegotiation
                  serviceNegotiation={serviceNegotiation}
                />
              )}
            </Container>
          </TabPanel>
        </TabContext>
      </Container>
    </Box>
  );
};

export default ShowServiceNegotiation;
