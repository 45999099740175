import { Box } from "@mui/material";
import PageTitle from "../../components/atoms/PageTitle";
import DuimpRequestForm from "../../components/molecules/DuimpRequestForm";

const DuimpRequest = () => {
  return (
    <Box
      sx={{
        width: "100%",
        padding: "16px",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <PageTitle title="NEW REQUEST - DUIMP" />
      <Box>
        <DuimpRequestForm />
      </Box>
    </Box>
  );
};

export default DuimpRequest;
