import { mdiContentSaveOutline } from "@mdi/js";
import Icon from "@mdi/react";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  Paper,
  TextField,
} from "@mui/material";
import i18next from "i18next";
import { enqueueSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import DuimpRequest from "../../../@types/services/DuimpRequest";
import { createDuimpRequest } from "../../../services/duimpRequests";
import {
  fetchMaterials,
  fetchMmUsers,
  fetchQuantityOfMaterialsOptions,
  fetchRequesterFieldOptions,
} from "../../../services/mm_requests";

type FormValues = {
  watchers: mmUser[];
  requester_field: string;
  quantity_of_materials: string;
  matnumber: material[];
  subject: string;
  description: string;
};

type quantityOfMaterialOptionsType = string[] | null;

type material = { id: number; matnumber: string };

type mmUser = {
  id: number;
  first_name: string;
  last_name: string;
  login: string;
};

const DuimpRequestForm = () => {
  const [quantityOfMaterialOptions, setQuantityOfMaterialOptions] =
    useState<quantityOfMaterialOptionsType>(null);
  const [requesterFieldOptions, setRequesterFieldOptions] = useState<string[]>(
    [],
  );
  const [materials, setMaterials] = useState<material[]>([]);
  const [watchersLoading, setWatchersLoading] = useState(false);
  const [materialsLoading, setMaterialsLoading] = useState(false);
  const [watchers, setWatchers] = useState<mmUser[]>([]);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm<FormValues>({});

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const formattedData: DuimpRequest = {
      reason: "DUIMP",
      watchers:
        data.watchers && data.watchers.length > 0
          ? data.watchers.map((watcher) => watcher.id)
          : [],
      requester_field: data.requester_field,
      quantity_of_materials: data.quantity_of_materials,
      matnumber:
        data.matnumber && data.matnumber.length > 0
          ? data.matnumber.map((material) => material.matnumber)
          : [],
      subject: data.subject,
      description: data.description,
    };
    setLoadingSubmit(true);
    await createDuimpRequest(formattedData)
      .then((response) => {
        enqueueSnackbar(i18next.t("duimpRequests.successMessage"), {
          variant: "success",
        });
        window.location.href = `/vbuyer/mm_requests?flash_message=${i18next.t("duimpRequests.flashMessage")}${response.mm_request.id}`;
      })
      .catch((error) => {
        setLoadingSubmit(false);
        enqueueSnackbar(i18next.t("duimpRequests.errorMessage"), {
          variant: "error",
        });
      });
  };

  const handleFetchMaterials = useCallback(async (filters?: any) => {
    if (filters && filters?.q?.length >= 3) {
      setMaterialsLoading(true);
      await fetchMaterials({
        filters,
      })
        .then((response) => {
          if (response.data) {
            const selectedMaterials = getValues("matnumber");
            if (selectedMaterials) {
              const combinedMaterials = [
                ...selectedMaterials,
                ...response.data.filter(
                  (item: { id: number }) =>
                    !selectedMaterials.some(
                      (selected) => selected.id === item.id,
                    ),
                ),
              ];
              setMaterials(combinedMaterials);
            } else {
              setMaterials(response.data);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar(i18next.t("duimpRequests.importDataErrorMessage"), {
            variant: "error",
          });
        });
      setMaterialsLoading(false);
    }
  }, []);

  const handleFetchMmUsers = async (filters?: any) => {
    if (filters && filters?.q?.length >= 2) {
      setWatchersLoading(true);
      await fetchMmUsers({
        filters,
      })
        .then((response) => {
          setWatchers(response.data);
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar(i18next.t("duimpRequests.importDataErrorMessage"), {
            variant: "error",
          });
        });
      setWatchersLoading(false);
    }
  };

  useEffect(() => {
    fetchQuantityOfMaterialsOptions().then((data) => {
      setQuantityOfMaterialOptions(data);
    });
    fetchRequesterFieldOptions().then((data) => {
      setRequesterFieldOptions(data);
    });
  }, []);

  return (
    <>
      <Paper
        sx={{
          padding: "24px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          flexShrink: 0,
          flexGrow: 1,
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              width: "100%",
            }}
          >
            <Controller
              name="watchers"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  loading={watchersLoading}
                  disablePortal
                  multiple
                  noOptionsText={i18next.t("duimpRequests.noOptionsText")}
                  options={watchers}
                  filterOptions={(x) => x}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={field.value || []}
                  getOptionLabel={(option) =>
                    `${option?.first_name} ${option?.last_name} - ${option?.login}`
                  }
                  onChange={(event, newValue) => {
                    field.onChange(newValue);
                  }}
                  sx={{ width: "100%" }}
                  onInputChange={(event, value) => {
                    handleFetchMmUsers({
                      q: value,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      key={params.inputProps.id}
                      label={i18next.t("duimpRequests.watchers")}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {watchersLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              )}
            />

            {requesterFieldOptions && (
              <Controller
                name="requester_field"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    disablePortal
                    options={requesterFieldOptions}
                    value={field.value || null}
                    onChange={(event, newValue) => {
                      field.onChange(newValue);
                    }}
                    sx={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={i18next.t("duimpRequests.requesterField")}
                      />
                    )}
                  />
                )}
              />
            )}

            {quantityOfMaterialOptions && (
              <Controller
                name="quantity_of_materials"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    disablePortal
                    options={quantityOfMaterialOptions}
                    value={field.value || null}
                    onChange={(event, newValue) => {
                      field.onChange(newValue);
                    }}
                    sx={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={i18next.t("duimpRequests.quantityOfMaterials")}
                      />
                    )}
                  />
                )}
              />
            )}

            <Controller
              name="matnumber"
              rules={{ required: i18next.t("duimpRequests.requiredMessage") }}
              control={control}
              render={({ field }) => (
                <Autocomplete
                  loading={materialsLoading}
                  disablePortal
                  multiple
                  noOptionsText={i18next.t("duimpRequests.noOptionsText")}
                  options={materials}
                  filterOptions={(x) => x}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  getOptionLabel={(option) => option.matnumber}
                  value={field.value || []}
                  onChange={(event, newValue) => {
                    field.onChange(newValue);
                  }}
                  sx={{ width: "100%" }}
                  onInputChange={(event, value) => {
                    handleFetchMaterials({
                      q: value,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      key={params.inputProps.id}
                      label={i18next.t("duimpRequests.material")}
                      helperText={
                        errors.matnumber
                          ? errors.matnumber.message
                          : i18next.t("duimpRequests.materialHelper")
                      }
                      error={!!errors.matnumber}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {materialsLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              )}
            />
            <Controller
              name="subject"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={i18next.t("duimpRequests.subject")}
                  helperText={i18next.t("duimpRequests.subjectHelper")}
                />
              )}
            />

            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  multiline
                  rows={6}
                  label={i18next.t("duimpRequests.description")}
                  helperText={i18next.t("duimpRequests.descriptionHelper")}
                />
              )}
            />

            <Divider />

            <Button
              variant="contained"
              type="submit"
              color="success"
              disabled={loadingSubmit}
              startIcon={
                loadingSubmit ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  <Icon path={mdiContentSaveOutline} size={1} />
                )
              }
            >
              {i18next.t("duimpRequests.saveButtonText")}
            </Button>
          </Box>
        </form>
      </Paper>
    </>
  );
};

export default DuimpRequestForm;
