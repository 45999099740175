import Proposal from "../@types/services/Proposal";
import api from "./api";

export type ProposalFields = {
  price: number;
};

export const fetchProposal = async (id: string): Promise<Proposal> => {
  const response = await api.get<Proposal>(`/proposals/${id}`, {
    baseURL: "/vbuyer/api/v2",
  });

  return response.data;
};

export const getProposalDocument = async (
  id: string,
  documentType: string,
): Promise<{
  url: string;
  filename: string;
}> => {
  const response = await api.get<{
    url: string;
    filename: string;
  }>("proposals/get_proposal_document", {
    baseURL: "/vbuyer/api/v2",
    params: {
      id,
      document_type: documentType,
    },
  });

  return response.data;
};

const addOrUpdateProposalAttachments = async (
  id: string,
  technicalProposal: File | undefined,
  commercialProposal: File | undefined,
): Promise<void> => {
  const formData = new FormData();
  if (technicalProposal) {
    formData.append("technical_proposal", technicalProposal);
  }
  if (commercialProposal) {
    formData.append("commercial_proposal", commercialProposal);
  }
  await api.postForm<Proposal>(
    `/proposals/${id}/add_attachments_proposal`,
    formData,
    {
      baseURL: "/vbuyer/api/v2",
    },
  );
};

export const editProposal = async (
  proposal: Proposal,
  proposalId: string,
): Promise<Proposal> => {
  const response = await api.putForm<Proposal>(
    `/proposals/${proposalId}`,
    {
      proposal: {
        id: proposal.id,
        proposal_lines_attributes: proposal.proposal_lines.map((pl) => ({
          id: pl.id,
          short_text: pl.short_text?.trim(),
          measure_unit_id: pl?.measure_unit?.id || pl.measure_unit,
          quantity: pl.quantity,
          gross_price: pl.gross_price,
          position: pl.position,
        })),
        delivery_date: proposal.delivery_date,
        currency_id: proposal.currency.id || proposal.currency,
      },
    },
    {
      baseURL: "/vbuyer/api/v2",
    },
  );

  addOrUpdateProposalAttachments(
    response.data.id,
    proposal.technicalProposal,
    proposal.commercialProposal,
  );
  return response.data;
};

const createProposal = async (
  proposal: Proposal,
  serviceNegotiationId: string,
): Promise<Proposal> => {
  if (proposal.technicalProposal && proposal.commercialProposal) {
    const response = await api.post<Proposal>(
      "/proposals",
      {
        proposal: {
          proposal_lines_attributes: proposal.proposal_lines.map((pl) => ({
            short_text: pl.short_text?.trim(),
            measure_unit_id: pl?.measure_unit?.id || pl.measure_unit,
            quantity: pl.quantity,
            gross_price: pl.gross_price,
            position: pl.position,
          })),
          delivery_date: proposal.delivery_date,
          currency_id: proposal.currency.id || proposal.currency,
          service_negotiation_id: serviceNegotiationId,
        },
      },
      {
        baseURL: "/vbuyer/api/v2",
      },
    );

    addOrUpdateProposalAttachments(
      response.data.id,
      proposal.technicalProposal,
      proposal.commercialProposal,
    );

    return response.data;
  }
  throw new Error("Technical and commercial proposals are required.");
};

export default createProposal;
