import { User } from "../@types/services";
import api from "./api";

export type UsersFilter = {
  email?: string;
  purchase_group?: number;
  user_group_name?: string;
};

interface UsersFetch {
  filters?: UsersFilter;
}

export const fetchUsers = async ({ filters }: UsersFetch): Promise<User[]> => {
  const response = await api.get<User[]>("/users", {
    baseURL: "/api/v2",
    params: {
      ...filters,
    },
  });

  return response.data;
};
