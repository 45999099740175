/* eslint-disable no-self-assign */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { mdiChevronDown } from "@mdi/js";
import Icon from "@mdi/react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import i18next from "i18next";
import { FC, useCallback, useEffect, useState } from "react";
import Audits from "../../../@types/services/Audits";
import AuditsPL from "../../../@types/services/AuditsPL";
import Proposal from "../../../@types/services/Proposal";
import ProposalLine from "../../../@types/services/ProposalLine";
import ServiceQuotation from "../../../@types/services/ServiceQuotation";
import Vendor from "../../../@types/services/Vendor";
import {
  ServiceNegotiationFilter,
  fetchServiceNegotiations,
} from "../../../services/serviceNegotiations";
import {
  activityProps,
  fetchActivityLogsByQuotation,
} from "../../../services/serviceQuotations";

interface ServiceNegotiationAuditsProps {
  serviceQuotation: ServiceQuotation;
}

const renderActivity = (activity: string) => {
  try {
    const json = JSON.parse(activity);
    return (
      <div style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
        {Object.entries(json).map(([key, value]) => (
          <div key={key}>
            <strong>{key}:</strong> {String(value)}
          </div>
        ))}
      </div>
    );
  } catch (e) {
    return <span>{activity}</span>;
  }
};

const ServiceNegotiationAudits: FC<ServiceNegotiationAuditsProps> = ({
  serviceQuotation,
}) => {
  const [serviceNegotiations, setServiceNegotiations] = useState<unknown[]>();
  const [activityLogs, setActivityLogs] = useState<activityProps[]>();
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);

  const handleFetchServiceQuotationAudits = useCallback(
    async (filters?: ServiceNegotiationFilter) => {
      setLoading(() => true);
      await fetchServiceNegotiations({
        filters,
      })
        .then((sq) => {
          setServiceNegotiations(() => sq.results || []);
        })
        .catch(() => {
          setNotFound(() => true);
        })
        .finally(() => {
          setLoading(() => false);
        });
    },
    [],
  );

  const handleFetchActivityLogs = useCallback(async (id: number) => {
    setLoading(() => true);
    await fetchActivityLogsByQuotation(id)
      .then((resource) => {
        setActivityLogs(resource);
      })
      .catch(() => {
        setNotFound(() => true);
      })
      .finally(() => {
        setLoading(() => false);
      });
  }, []);

  useEffect(() => {
    if (!serviceQuotation) {
      setNotFound(() => true);
      return;
    }

    handleFetchServiceQuotationAudits({
      service_quotation_id: serviceQuotation?.id,
    });
    handleFetchActivityLogs(serviceQuotation?.id);
  }, [
    handleFetchServiceQuotationAudits,
    handleFetchActivityLogs,
    serviceQuotation,
  ]);

  type ServiceNegotiationProps = {
    id: number;
    vendor: Vendor;
    proposal: Proposal;
    created_at: Date;
    audits: Audits;
  };

  return (
    <div>
      <Accordion>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            alignItems: "center",
            "& > *": {
              m: 1,
            },
          }}
        >
          <Grid2
            container
            spacing={2}
            sx={{
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Logs
          </Grid2>

          <AccordionSummary
            expandIcon={<Icon path={mdiChevronDown} size={0.8} />}
            aria-controls="panel1-content"
            id="panel1-header"
            disabled={!activityLogs}
          />
        </Box>
        <Divider />
        <AccordionDetails>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell align="center">Updated by</TableCell>
                  <TableCell align="center">action</TableCell>
                  <TableCell align="center">activity</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {activityLogs && activityLogs?.length > 0
                  ? activityLogs.map((activityLog: activityProps) => (
                      <TableRow key={activityLog.id}>
                        <TableCell>
                          {activityLog.act_tstamp &&
                            i18next.t("dateTime", {
                              val: Date.parse(activityLog.act_tstamp),
                              interpolation: {
                                escapeValue: false,
                              },
                              formatParams: {
                                val: {
                                  year: "numeric",
                                  month: "numeric",
                                  day: "numeric",
                                  hour: "numeric",
                                  minute: "numeric",
                                  second: "numeric",
                                  // hour12: false,
                                },
                              },
                            })}
                        </TableCell>
                        <TableCell align="center">
                          {activityLog.updated_by}
                        </TableCell>
                        <TableCell align="center">
                          {activityLog.act_action}
                        </TableCell>
                        <TableCell align="center">
                          {renderActivity(activityLog.activity)}
                        </TableCell>
                      </TableRow>
                    ))
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>

      <Divider sx={{ m: 1 }} />

      {serviceNegotiations && serviceNegotiations?.length > 0 ? (
        serviceNegotiations.map(
          (serviceNegotiation: ServiceNegotiationProps) => (
            <Accordion key={serviceNegotiation.id}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  alignItems: "center",
                  "& > *": {
                    m: 1,
                  },
                }}
              >
                <Grid2
                  container
                  spacing={2}
                  sx={{
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {serviceNegotiation.vendor.fullvendorname}
                </Grid2>

                <AccordionSummary
                  expandIcon={<Icon path={mdiChevronDown} size={0.8} />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  disabled={!serviceNegotiation.proposal}
                />
              </Box>
              <Divider />
              <AccordionDetails>
                <Accordion>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      alignItems: "center",
                      "& > *": {
                        m: 1,
                      },
                    }}
                  >
                    <Grid2
                      container
                      spacing={2}
                      sx={{
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      Proposal
                    </Grid2>

                    <AccordionSummary
                      expandIcon={<Icon path={mdiChevronDown} size={0.8} />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      disabled={!serviceNegotiation.proposal}
                    />
                  </Box>
                  <Divider />
                  <AccordionDetails>
                    {serviceNegotiation.proposal && (
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell> Date </TableCell>
                              <TableCell align="right">Field</TableCell>
                              <TableCell align="right">old value</TableCell>
                              <TableCell align="right">new value</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {serviceNegotiation.proposal.audits &&
                              serviceNegotiation.proposal.audits
                                .sort((a, b) => {
                                  return (
                                    new Date(a.created_at).getTime() -
                                    new Date(b.created_at).getTime()
                                  );
                                })
                                .map((audit: Audits) => (
                                  <TableRow key={audit.id}>
                                    <TableCell>
                                      {audit.created_at &&
                                        i18next.t("dateTime", {
                                          val: Date.parse(audit.created_at),
                                          interpolation: { escapeValue: false },
                                          formatParams: {
                                            val: {
                                              year: "numeric",
                                              month: "numeric",
                                              day: "numeric",
                                              hour: "numeric",
                                              minute: "numeric",
                                              second: "numeric",
                                              // hour12: false,
                                            },
                                          },
                                        })}
                                    </TableCell>
                                    <TableCell align="right">
                                      Delivery Date
                                      <br />
                                      Currency
                                    </TableCell>
                                    <TableCell align="right">
                                      {Array.isArray(
                                        audit.audited_changes.delivery_date,
                                      )
                                        ? audit.audited_changes.delivery_date[0]
                                        : "-"}
                                      <br />
                                      {Array.isArray(
                                        audit.audited_changes.currency_id,
                                      )
                                        ? audit.audited_changes.currency_id[0]
                                        : "-"}
                                    </TableCell>
                                    <TableCell align="right">
                                      {audit.audited_changes.delivery_date
                                        ? Array.isArray(
                                            audit.audited_changes.delivery_date,
                                          )
                                          ? audit.audited_changes
                                              .delivery_date[1]
                                          : audit.audited_changes.delivery_date
                                        : "-"}
                                      <br />
                                      {audit.audited_changes.currency_id
                                        ? Array.isArray(
                                            audit.audited_changes.currency_id,
                                          )
                                          ? audit.audited_changes.currency_id[1]
                                          : audit.audited_changes.currency_id
                                        : "-"}
                                    </TableCell>
                                  </TableRow>
                                ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      alignItems: "center",
                      "& > *": {
                        m: 1,
                      },
                    }}
                  >
                    <Grid2
                      container
                      spacing={2}
                      sx={{
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      Lines
                    </Grid2>

                    <AccordionSummary
                      expandIcon={<Icon path={mdiChevronDown} size={0.8} />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      disabled={!serviceNegotiation.proposal}
                    />
                  </Box>
                  <Divider />
                  <AccordionDetails>
                    {serviceNegotiation.proposal?.proposal_lines &&
                      serviceNegotiation.proposal.proposal_lines.map(
                        (proposalLine: ProposalLine) => (
                          <Accordion key={proposalLine.id}>
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                                flexDirection: "row",
                                alignItems: "center",
                                "& > *": {
                                  m: 1,
                                },
                              }}
                            >
                              <Grid2
                                container
                                spacing={2}
                                sx={{
                                  width: "100%",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {proposalLine.short_text}
                              </Grid2>

                              <AccordionSummary
                                expandIcon={
                                  <Icon path={mdiChevronDown} size={0.8} />
                                }
                                aria-controls="panel1-content"
                                id="panel1-header"
                                disabled={!serviceNegotiation.proposal}
                              />
                            </Box>
                            <Divider />
                            <AccordionDetails>
                              {proposalLine && (
                                <TableContainer component={Paper}>
                                  <Table
                                    sx={{ minWidth: 650 }}
                                    aria-label="simple table"
                                  >
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>Date</TableCell>
                                        <TableCell align="right">
                                          Field
                                        </TableCell>
                                        <TableCell align="right">
                                          old value
                                        </TableCell>
                                        <TableCell align="right">
                                          new value
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {proposalLine.audits &&
                                        proposalLine.audits.map(
                                          (audit: AuditsPL) => (
                                            <TableRow key={audit.id}>
                                              <TableCell>
                                                {audit.created_at &&
                                                  i18next.t("dateTime", {
                                                    val: Date.parse(
                                                      audit.created_at,
                                                    ),
                                                    interpolation: {
                                                      escapeValue: false,
                                                    },
                                                    formatParams: {
                                                      val: {
                                                        year: "numeric",
                                                        month: "numeric",
                                                        day: "numeric",
                                                        hour: "numeric",
                                                        minute: "numeric",
                                                        second: "numeric",
                                                        // hour12: false,
                                                      },
                                                    },
                                                  })}
                                              </TableCell>
                                              <TableCell align="right">
                                                GrossPrice
                                                <br />
                                                Quantity
                                              </TableCell>
                                              <TableCell align="right">
                                                {Array.isArray(
                                                  audit.audited_changes
                                                    .gross_price,
                                                )
                                                  ? audit.audited_changes
                                                      .gross_price[0]
                                                  : "-"}
                                                <br />
                                                {Array.isArray(
                                                  audit.audited_changes
                                                    .quantity,
                                                )
                                                  ? audit.audited_changes
                                                      .quantity[0]
                                                  : "-"}
                                              </TableCell>
                                              <TableCell align="right">
                                                {audit.audited_changes
                                                  .gross_price
                                                  ? Array.isArray(
                                                      audit.audited_changes
                                                        .gross_price,
                                                    )
                                                    ? audit.audited_changes
                                                        .gross_price[1]
                                                    : audit.audited_changes
                                                        .gross_price
                                                  : "-"}
                                                <br />
                                                {audit.audited_changes.quantity
                                                  ? Array.isArray(
                                                      audit.audited_changes
                                                        .quantity,
                                                    )
                                                    ? audit.audited_changes
                                                        .quantity[1]
                                                    : audit.audited_changes
                                                        .quantity
                                                  : "-"}
                                              </TableCell>
                                            </TableRow>
                                          ),
                                        )}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              )}
                            </AccordionDetails>
                          </Accordion>
                        ),
                      )}
                  </AccordionDetails>
                </Accordion>
              </AccordionDetails>
            </Accordion>
          ),
        )
      ) : (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
            alignItems: "center",
            "& > *": {
              m: 1,
            },
          }}
        >
          <Chip label={<span>{i18next.t("noChangeForNegotiation")}</span>} />
        </Box>
      )}
    </div>
  );
};

export default ServiceNegotiationAudits;
